import React, { useState, useEffect } from "react";
import { useTransition, animated } from "react-spring";

const ServiceCarousel = ({ services }) => {
  const [index, setIndex] = useState(0);

  const transitions = useTransition(index, {
    from: { opacity: 0, transform: "translate3d(100%,0,0)" },
    enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
    leave: { opacity: 0, transform: "translate3d(-50%,0,0)" },
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((state) => (state + 1) % services.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [services.length]);

  return (
    <div className="flex justify-center items-center">
      <div className="relative" style={{ width: '500px', height: '500px' }}>
        <div className="absolute inset-0 overflow-hidden">
          {transitions((style, i) => (
            <animated.div
              key={i}
              style={{
                ...style,
                position: 'absolute',
                width: '100%',
                height: '100%',
              }}
              className="bg-white bg-opacity-80 shadow-lg rounded-3xl overflow-hidden flex flex-col"
            >
              <div className="relative w-full h-1/2">
                <img
                  src={services[i].image}
                  alt={services[i].title}
                  className="w-full h-full object-cover"
                />
                <div className="absolute bottom-0 left-0 w-full bg-black bg-opacity-50 text-white p-2">
                  <h3 className="text-lg">{services[i].title}</h3>
                </div>
              </div>
              <div className="p-4 flex-grow overflow-y-auto">
                <h3 className="text-xl mb-2">{services[i].title}</h3>
                <p className="text-sm text-gray-600">
                  {services[i].description}
                </p>
              </div>
            </animated.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServiceCarousel;