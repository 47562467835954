import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import Logo from '../assets/website/hash.png';

// Custom hook for scrolling to top
const useScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
};

const Navbar = () => {
  // Use the custom hook
  useScrollToTop();

  const [isOpen, setIsOpen] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  const logoAnimation = useSpring({
    from: { opacity: 0, transform: 'scale(0.5)' },
    to: { opacity: 1, transform: 'scale(1.5)' },
    config: { tension: 300, friction: 10 },
  });

  const waveAnimation1 = useSpring({
    from: { transform: 'scale(1)', opacity: 0.7 },
    to: { transform: 'scale(2.5)', opacity: 0 },
    config: { duration: 3000 },
    loop: true,
    reset: true,
  });

  const waveAnimation2 = useSpring({
    from: { transform: 'scale(1)', opacity: 0.5 },
    to: { transform: 'scale(2.2)', opacity: 0 },
    config: { duration: 3000 },
    delay: 1000,
    loop: true,
    reset: true,
  });

  const waveAnimation3 = useSpring({
    from: { transform: 'scale(1)', opacity: 0.3 },
    to: { transform: 'scale(1.9)', opacity: 0 },
    config: { duration: 3000 },
    delay: 2000,
    loop: true,
    reset: true,
  });

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isVisible = prevScrollPos > currentScrollPos || currentScrollPos < 10;
      setVisible(isVisible);
      setIsScrolled(currentScrollPos > 10);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  const PhoneIcon = () => (
    <div className="relative">
      <animated.div style={waveAnimation1} className="absolute inset-0 bg-blue-500 rounded-full"></animated.div>
      <animated.div style={waveAnimation2} className="absolute inset-0 bg-blue-500 rounded-full"></animated.div>
      <animated.div style={waveAnimation3} className="absolute inset-0 bg-blue-500 rounded-full"></animated.div>
      <div className="absolute inset-0 border-2 border-blue-500 rounded-full"></div>
      <div className="absolute inset-0 border-4 border-blue-500 rounded-full"></div>
      <a href="https://wa.me/918086665500" target="_blank" rel="noopener noreferrer" className="relative flex items-center justify-center w-10 h-10 rounded-full bg-blue-600 z-10">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
        </svg>
      </a>
    </div>
  );

  return (
    <>
      <nav className={`fixed top-0 left-0 w-full z-20 transition-all duration-300 ${
        visible ? 'translate-y-0' : '-translate-y-full'
      } ${isScrolled ? 'bg-white shadow-md' : ''}`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-20">
            <div className="flex-shrink-0 pl-2 md:pl-0">
              <Link to="/" className="flex items-center">
                <animated.img
                  style={logoAnimation}
                  src={Logo}
                  alt="Hadi360 Logo"
                  className="h-24 w-auto sm:h-28 md:h-32" 
                />
              </Link>
            </div>
            <div className="hidden md:block">
              <div className="ml-10 flex items-baseline space-x-4">
                <Link to="/" className={`px-3 py-2 rounded-md text-sm font-medium ${location.pathname === '/' ? (isScrolled ? 'text-gray-900 bg-gray-200' : 'text-white bg-gray-900 bg-opacity-50') : (isScrolled ? 'text-gray-900 hover:bg-gray-200' : 'text-white hover:bg-gray-700 hover:bg-opacity-50')}`}>Home</Link>
                <Link to="/about" className={`px-3 py-2 rounded-md text-sm font-medium ${location.pathname === '/about' ? (isScrolled ? 'text-gray-900 bg-gray-200' : 'text-white bg-gray-900 bg-opacity-50') : (isScrolled ? 'text-gray-900 hover:bg-gray-200' : 'text-white hover:bg-gray-700 hover:bg-opacity-50')}`}>About Us</Link>
                <Link to="/services" className={`px-3 py-2 rounded-md text-sm font-medium ${location.pathname === '/services' ? (isScrolled ? 'text-gray-900 bg-gray-200' : 'text-white bg-gray-900 bg-opacity-50') : (isScrolled ? 'text-gray-900 hover:bg-gray-200' : 'text-white hover:bg-gray-700 hover:bg-opacity-50')}`}>Services</Link>
                <Link to="/contact" className={`px-3 py-2 rounded-md text-sm font-medium ${location.pathname === '/contact' ? (isScrolled ? 'text-gray-900 bg-gray-200' : 'text-white bg-gray-900 bg-opacity-50') : (isScrolled ? 'text-gray-900 hover:bg-gray-200' : 'text-white hover:bg-gray-700 hover:bg-opacity-50')}`}>Contact Us</Link>
              </div>
            </div>
            <div className="hidden md:block">
              <PhoneIcon />
            </div>
            <div className="md:hidden flex items-center space-x-4">
              <PhoneIcon />
              <button onClick={toggleNavbar} className={`inline-flex items-center justify-center p-2 rounded-md ${isScrolled ? 'text-gray-900 hover:bg-gray-200' : 'text-white hover:bg-gray-700 hover:bg-opacity-50'} focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white`}>
                <span className="sr-only">Open main menu</span>
                <svg className={`${isOpen ? 'hidden' : 'block'} h-6 w-6`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
                <svg className={`${isOpen ? 'block' : 'hidden'} h-6 w-6`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </nav>
      <div className={`fixed inset-0 z-10 bg-gray-900 bg-opacity-75 transition-opacity duration-300 ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
        <div className="flex flex-col h-full justify-center items-center space-y-8">
          <Link to="/" onClick={toggleNavbar} className={`px-3 py-2 rounded-md text-2xl font-medium ${location.pathname === '/' ? 'text-white bg-gray-900 bg-opacity-50' : 'text-white hover:bg-gray-700 hover:bg-opacity-50'}`}>Home</Link>
          <Link to="/about" onClick={toggleNavbar} className={`px-3 py-2 rounded-md text-2xl font-medium ${location.pathname === '/about' ? 'text-white bg-gray-900 bg-opacity-50' : 'text-white hover:bg-gray-700 hover:bg-opacity-50'}`}>About Us</Link>
          <Link to="/services" onClick={toggleNavbar} className={`px-3 py-2 rounded-md text-2xl font-medium ${location.pathname === '/services' ? 'text-white bg-gray-900 bg-opacity-50' : 'text-white hover:bg-gray-700 hover:bg-opacity-50'}`}>Services</Link>
          <Link to="/contact" onClick={toggleNavbar} className={`px-3 py-2 rounded-md text-2xl font-medium ${location.pathname === '/contact' ? 'text-white bg-gray-900 bg-opacity-50' : 'text-white hover:bg-gray-700 hover:bg-opacity-50'}`}>Contact Us</Link>
        </div>
      </div>
    </>
  );
};

export default Navbar;