import homeAutomationImage from "../assets/website/home.jpeg";
import homeTheatreImage from "../assets/website/z.jpeg";
import gateAutomationImage from "../assets/website/gate.jpeg";

import BgTex from "../assets/website/c.jpeg";
import BgTexture from "../assets/a.jpeg";
import BbImg from "../assets/website/g.jpeg";
import Btv from "../assets/website/z.jpeg";
import Bph from "../assets/website/k.jpeg";
import Bwt from "../assets/website/l.jpeg";
import Bsol from "../assets/website/v.jpeg";
import Lock from "../assets/website/lock.png";
import Monitor from "../assets/website/monitor.png";
import Wireless from "../assets/website/wireles.png";
import Footer from "./Footer";

const Home = () => {
  return (
    <div className="overflow-x-hidden">
      <div
        className="min-h-screen bg-cover bg-center bg-no-repeat relative"
        style={{ backgroundImage: `url(${BgTexture})` }}
      >
        <div>
          <div className="relative min-h-screen p-20 pt-[140px] ">
            <div className="mb-8 md:mb-0">
              <div className="max-w-xl text-white">
                <div className="max-w-xl w-full px-4 md:px-0">
                  <div className="text-[#FFF250] text-xs md:text-sm font-bold py-1 px-3 inline-block mb-1 md:mb-2 bg-opacity-20 bg-[#FFF250]">
                    ALWAYS IN CONTROL
                  </div>
                  <h1 className="text-3xl sm:text-2xl md:text-3xl lg:text-4xl font-bold mb-3 md:mb-2 leading-tight">
                    Elevate Your Lifestyle with
                    <span className="text-[#009DB8]"> Smart Home </span>
                    Solutions
                  </h1>
                  <p className="text-xs sm:text-sm md:text-base mb-2 md:mb-4">
                    A smart home service offers comprehensive professional
                    assistance in creating and maintaining an intelligent,
                    connected home environment.
                  </p>
                  <button className="bg-[#009DB8] text-white font-bold py-1.5 md:py-2 px-3 md:px-6 rounded-full hover:bg-[#007A8C] transition-colors duration-300 text-xs md:text-sm">
                    Discover More
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-[#005266] bg-opacity-80 text-white p-4 md:p-8 rounded-[25px] mt-8 md:mt-0 md:absolute md:bottom-0 md:left-20 md:right-20 overflow-y-auto max-h-[80vh]">
            <div className="container mx-auto flex flex-col space-y-6">
              <div className="flex flex-col md:flex-row md:space-x-4 space-y-6 md:space-y-0">
                <div className="flex-1 flex items-start space-x-4">
                  <div className="bg-[#FFF250] rounded-full p-3 flex-shrink-0">
                    <img src={Wireless} alt="" className="w-6 h-6" />
                  </div>
                  <div>
                    <h3 className="font-bold text-lg">Wirelessly Smart</h3>
                    <p className="text-sm mt-1 text-container  mb-6 max-w-2xl">
                      Uses wireless technology to control devices, offering
                      convenience and flexibility without extensive cabling.
                    </p>
                  </div>
                </div>
                <div className="flex-1 flex items-start space-x-4">
                  <div className="bg-[#FFF250] rounded-full p-3 flex-shrink-0">
                    <img src={Monitor} alt="" className="w-6 h-6" />
                  </div>
                  <div>
                    <h3 className="font-bold text-lg">24/7 Monitoring</h3>
                    <p className="text-sm mt-1 text-container mb-6 max-w-2xl">
                      24/7 monitoring in a smart home provides continuous
                      surveillance and management of security systems, ensuring
                      protection at all times.
                    </p>
                  </div>
                </div>
                <div className="flex-1 flex items-start space-x-4">
                  <div className="bg-[#FFF250] rounded-full p-3 flex-shrink-0">
                    <img src={Lock} alt="" className="w-6 h-6" />
                  </div>
                  <div>
                    <h3 className="font-bold text-lg">Smart Lock</h3>
                    <p className="text-sm mt-1 text-container  mb-6 max-w-2xl">
                      A smart lock enables keyless entry using smartphones or
                      voice commands, enhancing security and convenience for
                      homes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="min-h-screen bg-white flex items-center py-16">
        <div className="container mx-auto px-4 md:px-10">
          <div className="flex flex-col md:flex-row md:space-x-8">
            <div className="w-full md:w-1/2 lg:w-2/5 mb-8 md:mb-0 md:pr-4">
              <div className="grid grid-cols-2 gap-4">
                <div className="aspect-square  rounded-lg overflow-hidden">
                  <img
                    src={Bph}
                    alt=""
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="aspect-square  rounded-lg overflow-hidden">
                  <img
                    src={Bwt}
                    alt=""
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="aspect-square  rounded-[25px] overflow-hidden">
                  <img
                    src={Bsol}
                    alt=""
                    className="w-full h-auto max-w-[256px] max-h-[256px] object-cover rounded-[25px]"
                  />
                </div>
                <div className="aspect-square  rounded-lg overflow-hidden">
                  <img
                    src={Btv}
                    alt=""
                    className="w-full h-full object-cover"
                  />
                </div>
              </div>
            </div>

            <div className="w-full md:w-1/2 lg:w-3/5 md:pl-4 md:pt-16  ">
              <div className="text-[#009DB8] text-sm font-bold mb-4">
                About Us
              </div>
              <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-4 text-black">
                Achieve Efficiency And Comfort
                <br />
                Through Home Automation
              </h2>
              <p className="text-container text-gray-600 mb-6 max-w-2xl">
  Experience comfort and innovation with Hash360 Technologies' home automation solutions. Impeccably integrate technology for convenience and control in every aspect of your home. Harmonize lighting, climate, security, and entertainment for the perfect ambiance. Our expertise ensures an intelligent system adapting to your needs, enhancing efficiency and luxury.
</p>


              <button className="bg-[#009DB8] text-white font-bold py-2 px-4 md:px-6 rounded-full hover:bg-[#007A8C] transition-colors duration-300 text-sm md:text-base">
                Discover More
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="min-h-screen bg-cover bg-center bg-no-repeat relative flex items-center"
        style={{ backgroundImage: `url(${BgTex})` }}
      >
        <div className="container mx-auto px-4 relative z-10 flex justify-end">
          <div className="w-full md:w-1/2 bg-[#009DB8]/80 p-8 rounded-lg text-white">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">
              Effortless Living at Your
              <br />
              Fingertips With Automation
            </h2>
            <p className="text-sm md:text-base   text-container  mb-6 max-w-2xl">
              Automation brings effortless living by integrating smart
              technology into your home,allowing remote control of lights,
              temperature,and security systems with ease.
            </p>
            <button className="bg-[#00C2E0] text-white font-semibold py-2 px-6 rounded-full text-sm">
              Get Started
            </button>
          </div>
        </div>
      </div>

      <div className="bg-white py-16">
        <div className="container mx-auto px-4 md:px-10">
          <h2 className="text-2xl font-bold mb-2 text-[#009DB8]">
            Our Services
          </h2>

          <div className="md:gap-12 gap-6 mb-8 md:flex-row flex-col flex max-w-6xl">
            <div className="flex-1 md:max-w-[60%]">
              <h3 className="text-2xl sm:text-3xl md:text-4xl font-bold text-gray-900 text-sm md:text-base  text-container mb-6 max-w-2xl">
                Experience the future with Hash360 Technologies Home Automation
                Solutions
              </h3>
            </div>

            <div className="flex-1 flex flex-col justify-between space-y-8 md:max-w-[40%]">
              <p className="flex flex-col sm:flex-row items-center justify-between max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
                Hash360 Technologies aims to use technology to enhance daily
                life through automation. We strive to create smart home-friendly
                solutions that improve the human experience and provide a
                connected sustainable world.
              </p>

              <button className="bg-[#009DB8] text-white font-bold py-2 px-4 md:px-6 rounded-full hover:bg-[#007A8C] transition-colors duration-300 text-sm md:text-base self-start">
                All Services
              </button>
            </div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 lg:gap-8 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto text-container  mb-6 max-w-2xl flex flex-col sm:flex-row items-center justify-between max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
            {[
              {
                title: "Home Automation",
                description:
                  "Experience comfort & innovation with Hash360 Technologies Home Automation Solutions",
                bgColor: "bg-[#E6F7F9]",
                image: homeAutomationImage,
              },
              {
                title: "Home Theatre",
                description:
                  "Elevate your living space with Hash360 Technologies' state-of-the-art home theatre installations, bringing you cinematic magic",
                bgColor: "bg-white",
                image: homeTheatreImage,
              },
              {
                title: "Gate Automation",
                description:
                  "Ensure modern convenience with Hash360 Technologies' gate automation solutions - safe, efficient automation",
                bgColor: "bg-[#E6F7F9]",
                image: gateAutomationImage,
              },
            ].map((item, index) => (
              <div
                key={index}
                className={`${item.bgColor} shadow-md rounded-[25px] overflow-hidden flex flex-col`}
              >
                <div className="flex-grow flex items-center justify-center p-4">
                  <img
                    src={item.image}
                    alt={item.title}
                    className="w-full h-auto max-w-[256px] max-h-[256px] object-cover rounded-[25px]"
                  />
                </div>
                <div className="p-4 flex flex-col justify-between flex-grow">
                  <div>
                    <h2 className="text-xl font-bold mb-2">{item.title}</h2>
                    <p className="text-gray-600 mb-4">{item.description}</p>
                  </div>
                  <button className="bg-yellow-400 text-black font-bold py-2 px-4 rounded-full self-start hover:bg-yellow-500 transition duration-300">
                    Learn More
                  </button>
                </div>
              </div>
            ))}
          </div>

          <div className="text-left mt-8 ml-[9%]">
            <button className="border-2 border-[#009DB8] text-[#fff] font-bold py-2 px-4 md:px-6 rounded-full bg-[#009DB8] hover:text-white transition-colors duration-300 text-sm md:text-base">
              View All Services
            </button>
          </div>
        </div>
      </div>

      <div className="bg-[#cccccc] py-16">
        <div className="container mx-auto px-4 md:px-10">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="bg-white rounded-[25px] p-6 shadow-md text-center">
              <div className="bg-[#009DB8] w-12 h-12 rounded-full flex items-center justify-center mb-4 mx-auto">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 text-white"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path d="M12 2C8.13 2 5 5.13 5 9c0 2.38 1.19 4.47 3 5.74V17c0 .55.45 1 1 1h6c.55 0 1-.45 1-1v-2.26c1.81-1.27 3-3.36 3-5.74 0-3.87-3.13-7-7-7zm-1 14h2v1h-2v-1zm3-2H10v-1h4v1zm2.85-5.27l-.85.6V11h-2v-1.93l-2.28 1.61-2.29-1.61V11H7V9.33l-.85-.6C5.43 8.17 5 7.15 5 6h2c0 1.66 1.34 3 3 3s3-1.34 3-3h2c0 1.15-.43 2.17-1.15 2.73z" />
                </svg>
              </div>
              <h3 className="text-xl font-bold mb-2">Our Vision</h3>
              <p className="text-gray-600  justify-between max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
                Hash360 Technologies aims to use technology to enhance daily
                life through seamless automation. We strive to create the human
                experience and promote a connected, sustainable world.
              </p>
            </div>

            <div className="bg-white rounded-[25px] p-6 shadow-md text-center">
              <div className="bg-[#009DB8] w-12 h-12 rounded-full flex items-center justify-center mb-4 mx-auto">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-8 h-8 text-white"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <circle cx="12" cy="12" r="10"></circle>
                  <line x1="22" y1="12" x2="18" y2="12"></line>
                  <line x1="6" y1="12" x2="2" y2="12"></line>
                  <line x1="12" y1="6" x2="12" y2="2"></line>
                  <line x1="12" y1="22" x2="12" y2="18"></line>
                </svg>
              </div>
              <h3 className="text-xl font-bold mb-2">Our Mission</h3>
              <p className="text-gray-600  justify-between max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
                Our comprehensive services ranging from home automation, home
                theatre systems, and entrance automation, security systems &
                access control, and solar energy solutions are designed to
                enhance comfort, security, and sustainability.
              </p>
            </div>

            <div className="space-y-4">
              <h3 className="text-xl text-[#009DB8] font-bold mb-2">
                Our Value
              </h3>
              <h4 className="text-2xl font-bold mb-4">
                Let's Transform your Home Together
              </h4>
              <img
                src={BbImg}
                alt=""
                className="w-full h-48 object-cover rounded-[25px] mb-4"
              />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Home;
