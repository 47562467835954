import React from "react";
import entrance from "../assets/entrance.jpg";
import home_theatre from "../assets/home_theatre.jpg";
import home from "../assets/home.jpg";
import security from "../assets/security.jpg";
import solar from "../assets/solar.jpg";
import a from "../assets/a.jpg";
import c from "../assets/c.jpg";
import "../index.css";
import Footer from "./Footer";
import ServiceCarousel from "./ServiceCarousel";

const services = [
  {
    title: "Entrance Automation",
    description:
      "Explore modern convenience and security with Hash360 Technologies, your trusted partner in cutting-edge Entrance Automation. From residential to commercial spaces, we pave the way for a future where entrances become gateways to sophistication. Our entrance automation ensures flawless precision, be it sliding gates, rolling shutters, or responsive barriers, providing smooth and secure access. Experience a new level of security with our cutting-edge access control system, providing biometric entry, keyless access, and advanced surveillance integration for peace of mind.",
    image: entrance,
  },
  {
    title: "Security System & Access Control",
    description:
      "We specialize in state-of-the-art Security Systems and Access Control Installations, creating an impenetrable shield around your property. Our CCTV Systems redefine clarity, capturing every detail with precision for unparalleled visibility. Step into the future with our intelligent security solutions, immaculately integrating surveillance, access control, and alarm systems with smart technology for remote monitoring, instant alerts, and user-friendly control. Take control of your space with our advanced access control systems, from biometrics to keyless entry, enhancing security and minimizing risks.",
    image: security,
  },
  {
    title: "Solar",
    description:
      "We specialize in crafting Off-Grid, On-Grid, and Hybrid Solar Installations tailored to meet your energy needs. We bring the sun's boundless energy to your doorstep, redefining how you power your life for a brighter, greener tomorrow. Our Solar Installations commit to sustainability, converting sunlight into electricity to reduce your carbon footprint and contribute to a cleaner future. Maximize efficiency and savings with our On-Grid Solar installations, generating clean energy while connected to the grid for reduced bills and a greener future. ",
    image: solar,
  },
  {
    title: "Home Automation",
    description:
      "Experience comfort and innovation with Hash360 Technologies Home Automation Solutions. Impeccably integrate technology for convenience and control in every aspect of your home. Harmonize lighting, climate, security, and entertainment for the perfect ambiance. Our expertise ensures an intelligent system adapting to your needs, enhancing efficiency and luxury. With Hash360, enjoy advanced security, remote monitoring, and intuitive interfaces. Simplify your routine and contribute to energy conservation for a smarter, sustainable home. Explore smart living with our comprehensive automation options.",
    image: home,
  },
  {
    title: "Home Theatre",
    description:
      "Elevate your living space with Hash360 Technologies' advanced theatre installations, bringing cinematic magic to your home. Meticulously designed home theatre systems deliver stunning visuals and immersive sound, from high-definition projectors to premium surround sound. Beyond installation, our commitment continues with ongoing support for troubleshooting, upgrades, and staying ahead of technology. Experience cinema magic at home—contact Hash360 Technologies for a consultation and turn your home theatre vision into reality. Your entertainment journey starts here.",
    image: home_theatre,
  },
];

const Services = () => {
  return (
    <div className="relative">
      <div className="relative w-full h-400">
        <img
          src={a}
          alt="Services background"
          className="relative w-full h-full object-cover clip-path-inset brightness-50"
        />
      </div>

      <header className="relative mt-12 md:mt-24 lg:mt-32 mb-16 flex flex-col items-center px-4 sm:px-6 md:px-8 lg:px-12">
        <h2 className="text-xl md:text-2xl text-blue-500">What We Offer</h2>
        <h1 className="text-2xl md:text-4xl mt-2 text-center">
          Upgrade To a Smarter Living <br /> Experience With Home Automation
        </h1>
      </header>

      <section className="relative bg-gray-100 py-8 md:py-16 px-4 sm:px-6 md:px-8 lg:px-12">
        <div className="mb-8">
          <h3 className="text-xl md:text-2xl text-blue-500 text-left">
            Our Services
          </h3>
          <h2 className="text-2xl md:text-3xl text-left mt-2">
            Experience the future with Hash360 <br /> Technologies Home
            Automation <br /> Solutions
          </h2>
        </div>
        <div className="mb-8">
          <p className="text-base md:text-xl text-gray-600 justify-between max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
            Hash360 Technologies aims to use technology to enhance daily life
            through automation. We strive to create smart, user-friendly solutions that improve the human experience
            and promote a connected, sustainable world.
          </p>
        </div>
        <div className="flex justify-center mb-8">
          <button className="bg-blue-500 text-white rounded-full px-4 md:px-8 py-2 md:py-3 text-sm md:text-lg hover:bg-blue-700 transition duration-300">
            All Services
          </button>
        </div>

        <ServiceCarousel services={services} />
      </section>

      <div className="relative mt-16">
        <div className="min-h-screen bg-cover bg-center bg-no-repeat relative flex items-center"
             style={{ backgroundImage: `url(${c})` }}>
          <div className="container mx-auto px-4 relative z-10 flex justify-end">
            <div className="w-full md:w-1/2 bg-[#009DB8]/80 p-8 rounded-lg text-white">
              <h2 className="text-3xl md:text-4xl font-bold mb-4">
                Effortless Living at Your<br />
                Fingertips With Automation
              </h2>
              <p className="text-sm md:text-base mb-6 justify-between max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
                Automation brings effortless living by integrating smart technology into your
                home, allowing remote control of lights, temperature, and security systems with
                ease.
              </p>
              <button className="bg-[#00C2E0] text-white font-semibold py-2 px-6 rounded-full text-sm">
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Services;